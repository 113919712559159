<template>
  <div class="p-0">
    <div class="d-flex align-center">
      <Warning :openDialog="dialog" @handle-dialog="handleDialog" />
    </div>

    <Skeleton v-if="loading" />

    <!-- <div v-else @click.prevent="toDetail(data, data.slug)"> -->

    <div v-else>
      <v-card
        class="p-0"
        max-width="344"
        elevation="0"
        style="border: 1px solid black"
      >
        <!-- height: 368px -->
        <div @click="(e) => getModal(e, data.slug)">
          <v-img
            height="120"
            width="287"
            alt=""
            :lazy-src="data.image_url"
            :src="data.image_url"
            contain
          ></v-img>
          <v-card-text style="padding: 8px !important">
            <div class="item-text-header" style="font-size: 0.8em">
              {{ data.item_name }}
            </div>
          </v-card-text>

          <v-card-text style="padding: 0px 10px; height: 2em">
            <div
              style="font-size: 12px"
              v-if="data.item_status < 0"
              class="item-text-header"
            >
              {{ $t("message.product-deleted") }}
            </div>
          </v-card-text>
        </div>
        <div class="desc-item pt-2">
          <div
            class="d-flex"
            style="
              color: #ff0090;
              justify-content: space-between;
              padding-right: 3px;
              padding-left: 3px;
            "
          >
            <v-icon @click="addFavorite(data)" dark :color="isFavorite()">
              mdi-heart
            </v-icon>
            <span style="margin-right: 5px">
              <span style="font-size: 75%; vertical-align: baseline">
                {{ $t("message.without-tax") }}
              </span>
              <span style="font-size: 12px">
                {{ formatDecWithoutComa(data.price_without_tax) }}
              </span>
              <span style="font-size: 75%; vertical-align: baseline"> 円</span>
            </span>
          </div>
          <v-spacer></v-spacer>

          <div
            v-if="!conditionalPrice(data.price)"
            class="tax"
            style="font-size: 12px"
          >
            <span>
              {{ $t("message.with-tax") }}
            </span>
            <span style="font-size: 16px">
              {{ formatDecWithoutComa(data.price) }} 円
            </span>
          </div>
          <div v-else class="tax" style="padding-right: 3px; padding-left: 3px">
            <span style="font-size: 10px">
              {{ $t("message.with-tax") }}
            </span>
            <!-- NOTE: CONDITIONAL STYLE NUMBER DIGIT PRICE MORE THAN EQUALS 6 -->
            <span
              :style="
                numDigitPrice(data.price) >= 6
                  ? 'font-size: 14px;'
                  : 'font-size: 16px'
              "
            >
              {{ priceBeforeComa(data.price) }}
            </span>
            <span style="font-size: 12px; margin-left: -2px">
              {{ priceAfterComa(data.price) }}
            </span>
            <span style="font-size: 16px">円</span>
          </div>

          <div class="toko" style="padding-right: 3px; padding-left: 3px">
            <span style="font-size: 10px; white-space: nowrap">{{
              data.store_name
            }}</span>
          </div>

          <div
            class="mx-1 d-flex justify-center align-center"
            style="z-index: 5"
          >
            <v-btn
              depressed
              small
              :disabled="
                data.is_stock == 0 ||
                data.item_status < 0 ||
                data.stock == 0 ||
                data.stock_total == 0
              "
              block
              style="padding: 8px"
              color="#ff0090"
              @click="(e) => addCart(e, data)"
            >
              <v-icon
                v-if="data.stock !== 0"
                class="pe-1"
                color="white"
                size="20"
                small
              >
                mdi-cart-outline
              </v-icon>
              <div v-if="data.stock !== 0" style="color: white">
                {{ $t("message.btn-add-cart") }}
              </div>
              <div v-else-if="data.stock == 0" style="color: white">
                売り切れ
              </div>
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import moneyFormat from "../helpers/moneyFormat";
import Skeleton from "@/components/skeleton/Skeleton";
import Swal from "sweetalert2";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";
import priceBeforeComa from "../helpers/priceBeforeComa";
import priceAfterComa from "../helpers/priceAfterComa";
import Warning from "../components/Warning.vue";

export default {
  name: "Product",
  data() {
    return {
      notFound: require("../assets/image-not-found.png"),
      message: {},
      itemId: "",
      storeId: "",
      dialog: false,
      confirmation_product: null,
    };
  },
  components: {
    Skeleton,
    Warning,
  },

  props: {
    data: Object,
    loading: Boolean,
    slug_category: String,
  },
  mounted() {},
  methods: {
    getModal(e, data) {
      if (e == "ConfirmProduct") {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      } else {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      }
    },
    handleDialog(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.itemId,
          storeId: this.storeId,
          message: {
            title: this.message.title,
          },
        });
      }
      this.dialog = false;
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    // NOTE: FUNCTION CONDITIONAL HANDLE STYLE NUMBER DIGIT PRICE
    numDigitPrice(price) {
      let convertStr = String(price);
      let cekComa = convertStr.indexOf(".");
      return cekComa;
    },
    // NOTE: FUNCTION CONDITIONAL HANDLE PRICE WITHOUT COMA & WITH COMA
    conditionalPrice(price) {
      let strPrice = String(price);
      let flag = false;
      for (let i = 0; i < strPrice.length; i++) {
        if (strPrice[i] === ".") {
          flag = true;
          break;
        }
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    priceBeforeComa(price) {
      return priceBeforeComa(String(price));
    },
    priceAfterComa(price) {
      return priceAfterComa(String(price));
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money);
    },
    addCart(e, data) {
      localStorage.setItem("notes", "");
      let nameEvent;
      if (e.isTrusted) {
        nameEvent = "ConfirmProduct";
      }
      if (data.is_rapid_antigen_tools == 1) {
        this.getModal(nameEvent, data);
      } else {
        this.itemId = data.item_id;
        this.storeId = data.store_id;
        e.stopPropagation();

        this.$gtag.event("add-cart-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });

        let cookies = $cookies.get("isLogin");
        if (cookies == 0) {
          Swal.fire({
            icon: "error",
            text: this.$t("message.title-err-login-first"),
          });
        } else {
          if (data.is_alcohol_item == 1) {
            this.dialog = true;
          } else {
            this.message.title = this.$t("message.title-add-carts");
            this.$store.dispatch("carts_module/addCarts", {
              storeId: data.store_id,
              itemId: data.item_id,
              message: {
                title: this.message.title,
              },
            });
          }
        }
      }
    },
    toDetail(data, slug) {
      if (data.item_status < 0) {
        return 0;
      } else {
        this.$gtag.event("quickview-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });
        this.$router.push("/items/" + slug);
      }
    },
    addFavorite: function (data) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("wishlist-item-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });
        // let search = this.$route.query.search

        // console.log(this.$router.history.current.path);

        var payload = {};

        if (this.$router.history.current.path == "/") {
          payload = {
            item_id: data.item_id,
            page: "top_page",
          };
        } else if (this.$router.history.current.path == "/wishlist") {
          payload = {
            item_id: data.item_id,
            page: "wishlist",
          };
        } else if (this.$router.history.current.path == "/last-seen") {
          payload = {
            item_id: data.item_id,
            page: "last_seen",
          };
        } else if (this.$router.history.current.path == "/items") {
          payload = {
            item_id: data.item_id,
            search: data.item_name,
            page: "search",
          };
        } else if (
          this.$router.history.current.path.substring(0, 16) ==
          "/items/category/"
        ) {
          if (
            this.$router.history.current.path.substring(0, 18) ==
            "/items/category/c/"
          ) {
            let slug = this.$router.history.current.path.split("/");

            payload = {
              item_id: data.item_id,
              page: "child-category",
              slug: slug[4],
            };
          } else {
            let slug = this.$router.history.current.path.split("/");

            payload = {
              item_id: data.item_id,
              page: "category",
              slug: slug[3],
            };
          }
        } else if (
          this.$router.history.current.path.substring(0, 7) == "/store/"
        ) {
          let path = this.$router.history.current.path.split("/");

          payload = {
            item_id: data.item_id,
            page: "shop_detail",
            store_id: path[2],
          };
        }

        this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);

        // let search = data.item_name;
        // let itemId = data.item_id;
        // let slug = data.slug;
        // let shopId = this.shop_id;
        // let payload = {
        //   item_id: itemId,
        //   slug: slug,
        //   keyword: search,
        //   shop_id: shopId,
        //   slug_category: data.ctg_slug,
        // };
        // this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);
      }
    },
    isFavorite() {
      if (this.data.has_favourites === 1) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
  },
};
</script>
<style>
.toko {
  text-align: end;
  margin-right: 5px;
  margin-top: -10px;
}
.tax {
  text-align: end;
  margin-right: 5px;
}
.text_color {
  color: #ff0090;
  font-weight: 800;
}
.text_align {
  text-align: right;
}
.odd_even:nth-child(odd) {
  background-color: #eeeeee;
}
.odd_even:nth-child(even) {
  background-color: white;
}
.item-text {
  color: #263238;
  margin-bottom: 3em;
  font-family: roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-text-header {
  /* margin: 1px 12px 5px 12px; */
  color: #263238;
  font-family: roboto;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
}
.desc-item {
  /* background-color: #C8C2BC; */
  background-color: #ece8e4;
  /* height: 125px; */
  height: 100px;
}
.class-btn-cart {
  /* position: absolute;
  bottom: 0%;
  width: 180px; */
  /* z-index: 5;
  display: flex;
  justify-content: center;
  align-content: center; */
}

/* @media only screen and (max-width: 440px) {
  .class-btn-cart {
    position: absolute;
    bottom: 0%;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .class-btn-cart {
    position: absolute;
    bottom: 0%;
    width: 180px
  }
} */
</style>
