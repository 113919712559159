<template>
    <div>
        <Sidebar />

        <div class="ps-2 mt-7 pt-7">
            <Username />
        </div>

        <div style="margin-bottom: 5em">
            <HeaderNavigasi  />

            <div v-if="isLoading">
                <v-row>
                    <v-col
                        v-for="i in 10"
                        :key="i"
                        cols="6"
                        lg="2"
                        md="2"
                        sm="2"
                        active="1"
                        style="padding-bottom: 10px"
                    >
                        <Skeleton />
                    </v-col>
                </v-row>
            </div>

            <div>
                <div
                    v-if="similarProduct.length === 0"
                    class="parent-class text-center d-flex justify-center"
                    >
                    <div style="margin-top: 10%">
                        <h3>{{ $t("message.no-have-similar-product") }}</h3>
                    </div>
                </div>
            </div>

            <v-container>
                <v-row style="padding: 0px;">
                    <v-col
                    v-for="list in similarProduct"
                    :key="list.id"
                    cols="6"
                    lg="2"
                    md="2"
                    sm="2"
                    active="1"
                    style="padding: 3px"
                    >
                    <Product :data="list" />
                    </v-col>
                </v-row>

            </v-container>

            <div class="d-flex flex-row align-end justify-end mr-2">
                <v-icon @click.prevent="$router.go(-1)" size="30" color="black" class="p-0 m-0">
                    mdi-chevron-left
                </v-icon>
                {{ $t("message.back") }}
            </div>

            <div>
                <CategoryFooter />
            </div>
        </div>
        <Cashier />
    </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Product from "../components/Product.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import Cashier from "../components/Cashier.vue";
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
export default {
    name: "ViewAllSimilarProduct",
    components: {
        Sidebar,
        Product,
        Skeleton,
        CategoryFooter,
        Cashier,
        Username: User,
        HeaderNavigasi
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        async itemDetail() {
            this.isLoading = true;
            let slug = this.$router.history.current.params.slug;
            let data = await this.$store.dispatch(
                "itemDetail_module/itemDetail",
                slug
            );
            this.isLoading = false;
            return data;
        },
    },
    computed: {
        similarProduct() {
            return this.$store.state.itemDetail_module.similarProduct;
        },
    },
    created() {
        this.itemDetail()
    }
}
</script>

<style>

</style>